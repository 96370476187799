<template>
  <nav class="bg-transparent h-14 max-w-full overflow-auto text-sm group-has-[.no-crumbs]/site:hidden" aria-label="Murupolku">
    <ul v-if="currentBreadcrumbs" data-jest="breadcrumbs" class="no-scrollbar list-reset h-full grid grid-flow-col gap-2 overflow-x-auto justify-start px-container lg:container">
      <li class="whitespace-nowrap flex items-center">
        <NuxtLink to="/">
          <HomeIcon class="size-4" title="Etusivulle" aria-label="Etusivulle" />
        </NuxtLink>
        <ChevronRightIcon class="ml-2 size-3 text-secondary" aria-hidden="true" />
      </li>
      <template v-if="currentBreadcrumbs.length === 0">
        <li v-for="i in 3" :key="i" class="flex items-center">
          <span class="flex bg-gradient-to-r from-grey-50 md:from-grey-100 to-transparent rounded-full h-4 w-24" />
          <ChevronRightIcon
            v-if="i !== 3"
            class="ml-2 size-3 text-secondary"
            aria-hidden="true"
          />
        </li>
      </template>
      <template v-else>
        <li v-for="crumb in crumbs" :key="crumb.slug" class="whitespace-nowrap flex items-center">
          <span v-if="crumb.current" class="text-secondary">
            {{ crumb.value }}
          </span>
          <NuxtLink v-else :to="'/'+crumb.slug" :aria-current="crumb.current ? 'page' : 'false'">
            {{ crumb.value }}
          </NuxtLink>
          <ChevronRightIcon
            v-if="!crumb.current"
            class="ml-2 size-3 text-secondary"
            aria-hidden="true"
          />
        </li>
      </template>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { HomeIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { useBreadcrumbStore } from '~/stores/breadcrumb'
// import { useBreadcrumb } from '~/composables/useBreadcrumb'
import type { Breadcrumb } from '~/types/breadcrumb'

const breadcrumbStore = useBreadcrumbStore()
// const breadcrumb = useBreadcrumb()
// const route = useRoute()

const currentBreadcrumbs = computed(() => breadcrumbStore.current)
const crumbs = computed(() => currentBreadcrumbs.value ? currentBreadcrumbs.value.map((crumb: Breadcrumb, i: number) => ({ ...crumb, current: i === (currentBreadcrumbs.value?.length || 0) - 1 })) : [])

// @TODO: Check if the below code is needed
// Breadcrumbs are empty if not yet generated (e.g. direct navigation, refresh page, etc.)
// if (currentBreadcrumbs.value?.length === 0) {
//   breadcrumb.setBreadcrumbs(route)
// }
</script>
