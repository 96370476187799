<template>
  <div class="group/site">
    <SkipToContent />
    <!-- <GlobalMessage /> -->
    <SiteHeader />
    <BreadcrumbsComponent />
    <main id="main-content" class="min-h-[75vh]">
      <NuxtPage />
    </main>
    <BenefitBar />
    <SiteFooter />
    <ToastNotifications />
    <MainMenu />
  </div>
</template>

<script setup lang="ts">
const { locale } = useI18n()

const config = useRuntimeConfig()
useSeoMeta({
  titleTemplate: '%s | Tarvike.com',
  title: 'Laatutuotteet arkeen ja vapaalle',
  ogTitle: 'Laatutuotteet arkeen ja vapaalle',
  ogSiteName: 'Tarvike.com',
  ogUrl: config.public.BASE_URL,
  robots: 'INDEX,FOLLOW',
  ogImage: `${config.public.BASE_URL}/images/og/tarvike-og-image.png`,
  twitterImage: `${config.public.BASE_URL}/images/og/tarvike-og-image.png`,
  description: 'Ajoneuvot maalle, tielle ja vesille, laitteita metsään ja puutarhaan, varusteet arkeen ja vapaalle, varaosat, tarvikkeet, huolto.',
  ogDescription: 'Ajoneuvot maalle, tielle ja vesille, laitteita metsään ja puutarhaan, varusteet arkeen ja vapaalle, varaosat, tarvikkeet, huolto.'
})
useHead({
  htmlAttrs: {
    lang: locale.value
  }
})
</script>
